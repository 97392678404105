import {
  Body,
  Dialog,
  ErrorCard,
  Flex,
  Loader,
  Margin,
  StatusCard,
  styled,
  useOnMount,
} from '@kivra/react-components';
import { pick } from '@kivra/sdk/common';
import { captureException } from '@kivra/sdk/log';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import React, { useState } from 'react';
import {
  useCampaign,
  useCampaignStatus,
} from '../../../../context/campaignContext';
import { useCreateSenderRoute } from '../../../../context/senderContext';
import { goTo } from '../../../../routes/history';
import { getCopy } from '../../../../util/copy';
import { pluralDocumentTypeCopy } from '../../../../util/copyKeys';
import { getTimePeriodText } from '../../../../util/dates';
import type { PublishableCampaign } from './utils/publishableCampaign';

interface Props {
  save: () => Promise<FixThisAnyLater>;
  onClose: () => void;
  campaign: PublishableCampaign;
}

const errorCopyKeys = {
  publish: {
    title: 'campaigns__publish_dialog_error_title',
    body: 'campaigns__dialog_error_generic_body',
  },
  overlap: {
    title: 'campaigns__overlap_error_title',
    body: 'campaigns__overlap_error_body',
  },
} as const;

export const PublishDialog = ({
  campaign,
  onClose,
  save,
}: Props): React.JSX.Element => {
  const { getOverlappingCampaigns, activateCampaign } = useCampaign(false);
  const clientStatus = useCampaignStatus();
  const createRoute = useCreateSenderRoute();
  const [error, setError] = useState<keyof typeof errorCopyKeys | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [overlappingCampaigns, setOverlappingCampaigns] = useState<Campaign[]>(
    []
  );

  useOnMount(async () => {
    setIsLoading(true);
    try {
      const campaigns = await getOverlappingCampaigns(
        pick(campaign, [
          'attachFrom',
          'attachTo',
          'activeFrom',
          'activeTo',
          'documentType',
          'position',
          'targetGroup',
        ])
      );
      setOverlappingCampaigns(campaigns);
    } catch (error) {
      setError('overlap');
    }
    setIsLoading(false);
  });

  const saveAndPublish = async (): Promise<void> => {
    setError(undefined);
    try {
      const { id } = await save();
      await activateCampaign(id);
      goTo(createRoute({ id: 'ongoing-and-upcoming' }));
    } catch (error) {
      captureException(error as FixThisAnyLater);
      setError('publish');
    }
  };

  const getTitlesText = (campaigns: Campaign[]): string =>
    campaigns
      .map(c => `"${c.title || getCopy('campaigns__title_placeholder')}"`)
      .join(', ');

  const overlappingCampaignsElement = overlappingCampaigns.length > 0 && (
    <Margin top={24}>
      <StatusCard variant="information">
        <StatusCard.Text>
          {getCopy('campaigns__overlapping_campaigns', {
            campaigns: getTitlesText(overlappingCampaigns),
            fields: getCopy(
              campaign.documentType === 'receipt'
                ? 'campaigns__overlapping_fields_receipt'
                : 'campaigns__overlapping_fields_postal'
            ),
          })}
        </StatusCard.Text>
      </StatusCard>
    </Margin>
  );

  const errorElement = error && (
    <Margin top={24}>
      <ErrorCard severity="high">
        <ErrorCard.Title>{getCopy(errorCopyKeys[error].title)}</ErrorCard.Title>
        <ErrorCard.Text>{getCopy(errorCopyKeys[error].body)}</ErrorCard.Text>
      </ErrorCard>
    </Margin>
  );

  const actionText = getCopy(
    clientStatus === 'draft' ? 'btn__publish' : 'btn__update'
  );

  return (
    <Dialog.Confirmation
      dataTestId="PublishDialog"
      actionText={actionText}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={saveAndPublish}
      onClose={onClose}
      title={getCopy('campaigns__publish_dialog_title', {
        action: actionText,
        title: campaign.title || getCopy('campaigns__title_placeholder'),
      })}
      open={!isLoading}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      {isLoading ? (
        <Flex justifyContent="center">
          <Loader />
        </Flex>
      ) : (
        <Flex direction="column">
          <Body size="medium" gutterBottom>
            {getCopy('campaigns__campaign_will_be')}:
          </Body>
          <StyledList>
            {!campaign.tag && campaign.attachFrom && campaign.attachTo && (
              <li>
                <Body size="medium">
                  {`${getCopy('campaigns__shown', {
                    documentType: pluralDocumentTypeCopy(
                      campaign.documentType
                    ).toLocaleLowerCase(),
                  })} ${getTimePeriodText(
                    campaign.attachFrom,
                    campaign.attachTo
                  )}`}
                </Body>
              </li>
            )}
            {campaign.activeFrom && campaign.activeTo && (
              <li>
                <Body size="medium">
                  {`${getCopy(
                    'campaigns__publish_dialog_active'
                  )} ${getTimePeriodText(
                    campaign.activeFrom,
                    campaign.activeTo
                  )}`}
                </Body>
              </li>
            )}
            <li>
              <Body size="medium">{getCopy('campaigns__can_be_edited')}</Body>
            </li>
          </StyledList>
          {overlappingCampaignsElement}
          {errorElement}
        </Flex>
      )}
    </Dialog.Confirmation>
  );
};

const StyledList = styled.ul({
  margin: 0,
  padding: [0, 0, 0, '$spacing-32'],
});
