import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import type {
  Campaign,
  CampaignRequest,
  ClientStatusType,
  GetOverlappingCampaignsPayload,
  SegmentationJobInformation,
  SegmentationJobRequest,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { createContext, useContext } from 'react';
import type { CampaignType } from '../util/campaignType';

export interface CampaignContext {
  campaign?: Campaign;
  campaignType?: CampaignType;
  activateCampaign: (id: string) => Promise<void>;
  saveCampaign: (changes?: CampaignRequest) => Promise<Campaign>;
  deleteCampaign: () => Promise<void>;
  getOverlappingCampaigns: (
    campaign: Omit<GetOverlappingCampaignsPayload, 'id' | 'hasSegmentation'>
  ) => Promise<Campaign[]>;
  createSegmentationJob: (
    segmentation: SegmentationJobRequest,
    campaignId: string
  ) => Promise<SegmentationJobInformation>;
  deleteSegmentation: () => Promise<void>;
}

export const CampaignContext = createContext<CampaignContext>(
  null as FixThisAnyLater
);

const { Consumer: CampaignContextConsumer, Provider: CampaignContextProvider } =
  CampaignContext;

export const useCampaignStatus = (): ClientStatusType =>
  useContext(CampaignContext).campaign?.clientStatus || 'draft';

export const useCampaignType = (): {
  campaignType: CampaignType;
  isTagCampaign: boolean;
  isPostalCampaign: boolean;
  isReceiptCampaign: boolean;
} => {
  const { campaignType } = useContext(CampaignContext);
  if (!campaignType) {
    throw new Error('Campaign type is undefined');
  }

  return {
    campaignType,
    isTagCampaign: campaignType === 'by-tag',
    isPostalCampaign: campaignType === 'postal',
    isReceiptCampaign: campaignType === 'receipt',
  };
};

export function useCampaign(): Required<CampaignContext>;
export function useCampaign(throwIfUndefined: true): Required<CampaignContext>;
export function useCampaign(throwIfUndefined: false): CampaignContext;
export function useCampaign(throwIfUndefined = true): CampaignContext {
  const c = useContext(CampaignContext);
  if (throwIfUndefined && !c.campaign) {
    throw new Error('Campaign is undefined');
  }
  return c;
}

export { CampaignContextConsumer, CampaignContextProvider };
