import { useOnMount } from '@kivra/react-components';
import React, { useContext } from 'react';
import { useConfig } from '../../context/globalContext';

const FontContext = React.createContext<{ fonts: string[] }>({
  fonts: [],
});

export const useEditorFonts = (): {
  fonts: string[];
} => {
  const context = useContext(FontContext);
  return context;
};

const FONT_STYLE_ELEMENT_ID = 'bannerEditorFonts';

type Props = {
  children?: React.ReactNode;
};

export const FontProvider = ({ children }: Props): React.JSX.Element => {
  const config = useConfig();
  const fonts = parseFontsConfig(config.kivra_campaigns_google_font_families);

  useOnMount(() => {
    if (!isFontStylesheetInDOM()) {
      const href = `${
        config.kivra_campaigns_google_fonts_api_origin
      }/css2${generateGoogleStylesheetQuery(fonts)}`;
      appendStylesheet(href, FONT_STYLE_ELEMENT_ID);
    }
  });

  return (
    <FontContext.Provider value={{ fonts }}>{children}</FontContext.Provider>
  );
};

const isFontStylesheetInDOM = (): boolean =>
  Boolean(document.getElementById(FONT_STYLE_ELEMENT_ID));

const appendStylesheet = (href: string, id: string): void => {
  const link = document.createElement('link');
  link.href = href;
  link.id = id;
  link.rel = 'stylesheet';
  link.crossOrigin = 'anonymous';
  document.getElementsByTagName('head')[0].appendChild(link);
};

const generateGoogleStylesheetQuery = (fonts: string[]): string => {
  const variants = 'ital,wght@0,400;0,700;1,400;1,700';
  return (
    fonts.reduce(
      (query, fontName) => `${query}family=${fontName}:${variants}&`,
      '?'
    ) + 'display=block'
  );
};

const parseFontsConfig = (config: string): string[] =>
  config.replaceAll('_', ' ').split(',');
