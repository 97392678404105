import { Button, Dialog, styled } from '@kivra/react-components';
import { PaletteIcon } from '@kivra/react-components/icons';
import type { BannerConfig } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import React, { useRef, useState } from 'react';
import { BannerEditor } from '../../../../components/bannerEditor/BannerEditor';
import { getCopy } from '../../../../util/copy';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';

type Props = {
  onChange: (image: string, options: BannerConfig) => void;
  options: BannerConfig;
  disabled: boolean;
};

export const BannerEditorContainer = ({
  onChange,
  disabled,
  options,
}: Props): React.JSX.Element => {
  const [showBannerTool, setShowBannerTool] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const showBannerToolButtonRef = useRef<HTMLButtonElement>(null);

  const onImageChange = (image: string, options: BannerConfig): void => {
    onChange(image, options);
    setShowBannerTool(false);
  };

  const onClose = (): void => {
    if (isDirty) {
      setShowCancelDialog(true);
    } else {
      setShowBannerTool(false);
    }
  };

  const onEdit = (newOptions: BannerConfig): void => {
    if (isEqualJSON(newOptions, options)) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  };

  return (
    <>
      <Root>
        <Button
          disabled={disabled}
          variant="link"
          size="small"
          onClick={() => setShowBannerTool(true)}
          ref={showBannerToolButtonRef}
        >
          <Button.Icon
            iconComponent={PaletteIcon}
            color={disabled ? '$surface-disabled' : '$text-primary'}
          />
          {getCopy('campaigns__create_banner')}
        </Button>
      </Root>
      <Dialog.Passive
        title={getCopy('campaigns__banner_editor_create')}
        open={showBannerTool}
        onClose={onClose}
        ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
        onDismissFocusRef={showBannerToolButtonRef}
        width="stretched"
      >
        <BannerEditor
          onSave={onImageChange}
          options={options}
          onEdit={onEdit}
        />
      </Dialog.Passive>
      {showCancelDialog && (
        <UnsavedChangesDialog
          open={true}
          onClose={() => setShowCancelDialog(false)}
          onAccept={() => {
            setShowCancelDialog(false);
            setShowBannerTool(false);
          }}
        />
      )}
    </>
  );
};

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: [0, '$spacing-8'],
});

const isEqualJSON = (
  a: Record<string, unknown>,
  b: Record<string, unknown>
): boolean => JSON.stringify(a) === JSON.stringify(b);
