import { getCopyString } from '@kivra/sdk/copy-consumer';
import type { Copy, GetCopyArguments } from '../copy';
import type { CopyKeysWithoutArgs } from '../types/copyKeys';

let copy: Copy | undefined;

export const setCopy = (newCopy: Copy): void => {
  copy = newCopy;
};

export const getCopy = (
  ...args: GetCopyArguments | [CopyKeysWithoutArgs]
): string => {
  if (copy === undefined) {
    throw new Error('getCopy used before setCopy');
  }
  return getCopyString<GetCopyArguments>(copy as Copy)(
    ...(args as GetCopyArguments)
  );
};
