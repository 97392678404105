import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FullPageLoader, ErrorPage } from '@kivra/react-components';
import type { Organization, SenderSearchResult } from '@kivra/sdk/senders';
import { getOrganization, getOrganizationSenders } from '@kivra/sdk/senders';
import type { ServiceState } from '../types/serviceState';
import {
  createErrorServiceState,
  createSuccessServiceState,
  loadingServiceState,
} from '../util/serviceStates';
import { logoutUser } from '../util/authentication';
import { getCopy } from '../util/copy';
import { useConfig } from './globalContext';
import { SendersContextProvider } from './sendersContext';

type Props = {
  children?: React.ReactNode;
};

export const SendersProvider = ({ children }: Props): React.JSX.Element => {
  const [sendersService, setSendersService] =
    useState<ServiceState<SenderSearchResult[]>>(loadingServiceState);
  const [organization, setOrganization] = useState<Organization>();
  const [noSenders, setNoSenders] = useState(false);
  const organizationId = useRouteMatch<{ organizationId: string }>().params
    .organizationId;
  const adminOrganizationId =
    useConfig().sender_backoffice_admin_organization_id;

  const { response: tenants, loading, error } = sendersService;

  useEffect(() => {
    Promise.all([
      getOrganizationSenders(organizationId),
      getOrganization(organizationId),
    ])
      .then(([senders, organization]) => {
        setOrganization(organization);
        if (senders.length === 0) {
          setNoSenders(true);
        }
        setSendersService(createSuccessServiceState(senders));
      })
      .catch(error => {
        setSendersService(createErrorServiceState(error));
      });
  }, [organizationId]);

  if (loading) {
    return <FullPageLoader />;
  }
  if ((noSenders && organizationId !== adminOrganizationId) || !organization) {
    /**
     * @todo: Investigate how navigation should be done and consider adding the topbar as soon as the user is authenticated,
     * thus providing the option to logout manually. At the time of writing this, organizations without tenants are not rendered
     * in the organization selector except for the admin organization. Tberefore this state should only be reachable by either
     * deeplinking or by being member of a single organization only which has no tenants (again the admin organization is the exception).
     */

    logoutUser({ shouldNavigate: false });
    return (
      <ErrorPage
        title={getCopy('campaigns__no_tenants')}
        body={getCopy('campaigns__no_tenants_error_description')}
      />
    );
  }
  if (error || !tenants) {
    return <ErrorPage useDefaultTexts />;
  }
  return (
    <SendersContextProvider value={{ senders: tenants, organization }}>
      {children}
    </SendersContextProvider>
  );
};
