import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';

export type PublishableCampaign = Partial<Campaign> &
  Pick<
    Campaign,
    | 'activeFrom'
    | 'activeTo'
    | 'attachFrom'
    | 'attachTo'
    | 'documentType'
    | 'image'
    | 'position'
    | 'targetGroup'
  >;

export const isCampaignPublishable = (
  campaign: Partial<Campaign>
): campaign is PublishableCampaign => {
  const isPublishable =
    Boolean(campaign.activeFrom) &&
    Boolean(campaign.activeTo) &&
    Boolean(campaign.documentType) &&
    Boolean(campaign.image) &&
    Boolean(campaign.position) &&
    Boolean(campaign.targetGroup);

  if (!campaign.tag) {
    return (
      isPublishable &&
      Boolean(campaign.attachFrom) &&
      Boolean(campaign.attachTo)
    );
  }

  return isPublishable;
};
