import type {
  Campaign,
  CampaignRequest,
  SegmentationJobRequest,
  GetOverlappingCampaignsPayload,
  SegmentationJobInformation,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import type { ApiResponseError } from '@kivra/sdk/common';
import { Button, ErrorPage, FullPageLoader } from '@kivra/react-components';
import React, { useEffect, useState } from 'react';
import { getRoute, goBack } from '../routes/history';
import { getCopy } from '../util/copy';
import {
  activateCampaign,
  getCampaign,
  deleteCampaign,
  createCampaign,
  updateCampaign,
  getOverlappingCampaigns,
  createSegmentationJob,
  deleteSegmentation,
} from '../util/handleUnauthorizedErrorWrapper';
import { getCampaignType, type CampaignType } from '../util/campaignType';
import { CampaignContextProvider } from './campaignContext';

type Props = {
  id: string;
  organizationKey: string;
  senderKey: string;
  onCampaignUpdate?: (campaign: Campaign) => void;
  campaignType?: CampaignType;
  children?: React.ReactNode;
};

export const CampaignProvider = ({
  children,
  id,
  organizationKey,
  onCampaignUpdate,
  senderKey,
  campaignType,
}: Props): React.JSX.Element => {
  const [campaign, setCampaign] = useState<Campaign>();
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState(campaignType);
  const [error, setError] = useState<ApiResponseError>();
  const isNewCampaign = id === 'new';

  useEffect(() => {
    if (isNewCampaign) {
      setType(campaignType);
      setCampaign(undefined);
      setIsLoading(false);
    } else if (campaign?.id !== id) {
      setIsLoading(true);
      getCampaign(organizationKey, senderKey, id)
        .then(response => {
          setCampaign(response);
          setType(getCampaignType(response));
        })
        .catch(error => setError(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, campaignType]);

  const updateCampaignState = (updatedCampaign: Campaign): void => {
    setCampaign(updatedCampaign);
    onCampaignUpdate?.(updatedCampaign);
  };

  const saveCampaign = async (changes?: CampaignRequest): Promise<Campaign> => {
    let updatedCampaign;
    if (isNewCampaign) {
      try {
        updatedCampaign = await createCampaign(
          organizationKey,
          senderKey,
          changes || {}
        );
      } catch (e) {
        return Promise.reject(e);
      }
    } else if (changes) {
      try {
        updatedCampaign = await updateCampaign(
          organizationKey,
          senderKey,
          id,
          changes
        );
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      return Promise.reject('You cannot update a campaign without changes');
    }
    updateCampaignState(updatedCampaign);
    return Promise.resolve(updatedCampaign);
  };

  const tryToGetCampaignId = (onErrorText: string): string => {
    if (!campaign) throw new Error(onErrorText);
    return campaign.id;
  };

  const handleActivateCampaign = (id: string): Promise<void> =>
    activateCampaign(organizationKey, senderKey, id);

  const handleGetOverlappingCampaigns = (
    overlapParams: Omit<
      GetOverlappingCampaignsPayload,
      'id' | 'hasSegmentation'
    >
  ): Promise<Campaign[]> =>
    getOverlappingCampaigns(organizationKey, senderKey, {
      id: campaign?.id,
      hasSegmentation: Boolean(campaign?.segmentation),
      ...overlapParams,
    });

  const handleCreateSegmentationJob = (
    segmentation: SegmentationJobRequest,
    campaignId: string
  ): Promise<SegmentationJobInformation> => {
    const segmentationJob = createSegmentationJob(
      organizationKey,
      senderKey,
      campaignId,
      segmentation
    );
    return segmentationJob;
  };

  const handleDeleteSegmentation = (): Promise<void> =>
    deleteSegmentation(
      organizationKey,
      senderKey,
      tryToGetCampaignId('Cannot delete segmentation with undefined id')
    );

  const handleDeleteCampaign = (): Promise<void> =>
    deleteCampaign(
      organizationKey,
      senderKey,
      tryToGetCampaignId('Cannot delete campaign with undefined id')
    );

  return (
    <CampaignContextProvider
      value={{
        campaign,
        saveCampaign,
        campaignType: type,
        createSegmentationJob: handleCreateSegmentationJob,
        deleteCampaign: handleDeleteCampaign,
        getOverlappingCampaigns: handleGetOverlappingCampaigns,
        activateCampaign: handleActivateCampaign,
        deleteSegmentation: handleDeleteSegmentation,
      }}
    >
      {error ? (
        <ErrorPage title={getCopy('campaigns__get_campaign_error_title')}>
          <Button
            fullWidth={false}
            variant="secondary"
            onClick={() =>
              goBack(
                getRoute({
                  id: 'ongoing-and-upcoming',
                  organizationId: organizationKey,
                  senderKey,
                })
              )
            }
          >
            {getCopy('btn__backward')}
          </Button>
        </ErrorPage>
      ) : isLoading ? (
        <FullPageLoader />
      ) : (
        children
      )}
    </CampaignContextProvider>
  );
};
