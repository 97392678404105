import React from 'react';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { Flex, Margin, Body, styled, css } from '@kivra/react-components';
import { Statistics } from '../../../components/statistics/Statistics';
import { CampaignReadonlyDetails } from '../../../components/CampaignReadonlyDetails';
import { I } from '../../../components/Italic';

type Props = {
  campaign: Campaign;
};

export const CampaignSummary = ({ campaign }: Props): React.JSX.Element => {
  const { organization, sender, clientStatus, image } = campaign;
  const showStatistics = ['ongoing', 'previous'].includes(clientStatus);
  return (
    <>
      <Flex>
        <Body size="medium">{sender.name} |&nbsp;</Body>
        <Body color="$text-secondary" size="medium">
          {organization.name}
        </Body>
      </Flex>
      {['draft', 'upcoming'].includes(clientStatus) && (
        <Margin top={24} bottom={24}>
          <Body size="medium">
            <I className={css({ textTransform: 'capitalize' })}>
              {clientStatus}
            </I>
          </Body>
        </Margin>
      )}
      {image && (
        <Margin top={16} bottom={48}>
          <Flex>
            {showStatistics && (
              <>
                <StatsContainer>
                  <Statistics campaign={campaign} />
                </StatsContainer>
                <VerticalDivider />
              </>
            )}
            <ImageContainer>
              <CampaignImg src={image} showStatistics={showStatistics} />
            </ImageContainer>
          </Flex>
        </Margin>
      )}
      <CampaignDetailsContainer data-test-id="AdminCampaignDetails">
        <CampaignReadonlyDetails campaign={campaign} />
      </CampaignDetailsContainer>
    </>
  );
};

const CampaignDetailsContainer = styled('div')({
  width: '100%',
  padding: ['$spacing-16', '$spacing-24'],
  backgroundColor: '$background-primary',
  borderRadius: '$radius-medium',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: '$spacing-8',
  rowGap: '$spacing-12',
});

const VerticalDivider = styled('div')({
  borderRight: `1px solid $border-subtle`,
  paddingRight: '$spacing-32',
  marginRight: '$spacing-32',
});

const CampaignImg = styled.img<{ showStatistics: boolean }>(
  ({ showStatistics }) => ({
    width: showStatistics ? '100%' : '50%',
  })
);

const ImageContainer = styled('div')({
  alignSelf: 'flex-end',
  flex: 1,
});

const StatsContainer = styled('div')({
  width: 436,
});
