import type {
  Campaign,
  CampaignRequest,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { getCampaign, createCampaign } from './handleUnauthorizedErrorWrapper';

export const duplicateCampaign = (
  organizationId: string,
  senderKey: string,
  campaignId: string
): Promise<Campaign> =>
  getCampaign(organizationId, senderKey, campaignId).then(sourceCampaign => {
    const {
      destinationUrl,
      documentType,
      image,
      position,
      targetGroup,
      useSso,
    } = sourceCampaign;
    const destinationCampaign: CampaignRequest = {
      destinationUrl,
      documentType,
      imageUrl: image || undefined,
      position,
      tag: sourceCampaign.tag ? `${sourceCampaign.tag} copy` : null,
      targetGroup,
      title: `${sourceCampaign.title} copy`,
      useSso,
    };

    return createCampaign(organizationId, senderKey, destinationCampaign);
  });
