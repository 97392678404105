import { css, ErrorCard, Flex, Loader, Margin } from '@kivra/react-components';
import React from 'react';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import type { CampaignMetrics } from '../__generated__/graphql';
import { useCampaignMetricsQuery } from '../__generated__/graphql';
import { getCopy } from '../util/copy';
import { getUntypedSenderKey } from '../util/sender';

type Props = {
  children: (props: {
    lastUpdated: string | null;
    metrics: CampaignMetrics;
  }) => React.JSX.Element;
  campaign: Campaign;
};

export const FetchCampaignMetrics = ({
  children,
  campaign,
}: Props): React.JSX.Element => {
  const { data, loading, error } = useCampaignMetricsQuery({
    variables: {
      senderKey: getUntypedSenderKey(campaign.sender.key),
      organizationKey: campaign.organization.key,
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return (
      <Flex
        justifyContent="center"
        className={css({
          padding: '$spacing-24',
          maxWidth: '450px',
        })}
      >
        <Loader />
      </Flex>
    );
  }

  const metricsResult = data?.sender.campaignsDomain.campaign.metrics;
  const lastUpdated = data?.sender.metrics.meta.lastUpdated;

  if (error || !metricsResult) {
    return (
      <Margin bottom={12} test-data-id="CampaignMetricsErrorHigh">
        <ErrorCard severity="high">
          <ErrorCard.Title>
            {getCopy('campaigns__statistics_content_error')}
          </ErrorCard.Title>
        </ErrorCard>
      </Margin>
    );
  }

  // Show same error message for NOT_FOUND and UNAVAILABLE_FOR_LEGAL_REASONS
  if ('error' in metricsResult) {
    return (
      <Margin bottom={12} test-data-id="CampaignMetricsErrorLow">
        <ErrorCard severity="low">
          <ErrorCard.Title>
            {getCopy('campaigns__statistics_content_error')}
          </ErrorCard.Title>
        </ErrorCard>
      </Margin>
    );
  }

  return children({ metrics: metricsResult, lastUpdated });
};
