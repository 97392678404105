import { Select, useOnMount } from '@kivra/react-components';
import type { ComponentProps } from 'react';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useCampaignStatus } from '../../../../../context/campaignContext';
import type {
  CampaignForm,
  CampaignFormKey,
} from '../../../../../types/campaignForm';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getCopy } from '../../../../../util/copy';
import { Block } from '../block/Block';

interface Props {
  name: Extract<CampaignFormKey, 'documentType' | 'targetGroup' | 'position'>;
  options: ComponentProps<typeof Select>['options'];
}

export const SelectField = ({
  name,
  options,
}: Props): React.JSX.Element | null => {
  const campaignStatus = useCampaignStatus();
  const firstOption = options[0];
  const { control } = useFormContext<CampaignForm>();
  const { field } = useController({
    name,
    control,
  });

  useOnMount(() => {
    if (!field.value) {
      field.onChange(firstOption.value);
    }
  });

  const blockProps = {
    documentType: {
      label: getCopy('campaigns__document_type'),
      tooltipText: getCopy('campaigns__document_type_tooltip'),
    },
    position: {
      label: getCopy('campaigns__position'),
      tooltipText: getCopy('campaigns__position_tooltip'),
    },
    targetGroup: {
      label: getCopy('campaigns__target_group'),
    },
  } as const;

  return options.length > 1 ? (
    <Block.Wrapper {...blockProps[name]}>
      <Select
        disabled={isOngoing(campaignStatus)}
        size="small"
        value={field.value || firstOption.value}
        options={options}
        onChange={field.onChange}
      />
    </Block.Wrapper>
  ) : null;
};
