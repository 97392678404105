import type { ApiResponseError } from '@kivra/sdk/common';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import {
  activateCampaign as activateCampaignFeSdk,
  createCampaign as createCampaignsFeSdk,
  createSegmentationJob as createSegmentationJobFeSdk,
  deactivateCampaign as deactivateCampaignFeSdk,
  deleteCampaign as deleteCampaignFeSdk,
  deleteSegmentation as deleteSegmentationFeSdk,
  getAdminOrganizationCampaigns as getAdminOrganizationCampaignsFeSdk,
  getCampaign as getCampaignFeSdk,
  getCampaignStatistics as getCampaignStatisticsFeSdk,
  getCampaigns as getCampaignsFeSdk,
  getIsSsoEnabled as getIsSsoEnabledFeSdk,
  getOrganizationCampaigns as getOrganizationCampaignsFeSdk,
  getOverlappingCampaigns as getOverlappingCampaignsFeSdk,
  getSegmentationJobStatus as getSegmentationJobStatusFeSdk,
  updateCampaign as updateCampaignFeSdk,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { logoutUser } from './authentication';

export const renewAccessToken = async (): Promise<void> => {
  logoutUser({ shouldClearTenantStorage: false, shouldNavigate: false });
  window.location.reload();
};

export const handleUnauthenticatedRequest =
  <
    OriginalRequest extends (
      ...args: FixThisAnyLater[]
    ) => Promise<FixThisAnyLater>,
  >(
    request: OriginalRequest
  ) =>
  (...args: Parameters<typeof request>): ReturnType<OriginalRequest> =>
    request(...args).catch((error: ApiResponseError) => {
      if (error.httpStatusCode === 401) {
        void renewAccessToken();
      } else {
        throw error;
      }
    }) as ReturnType<OriginalRequest>;

export const activateCampaign = handleUnauthenticatedRequest(
  activateCampaignFeSdk
);

export const getCampaign = handleUnauthenticatedRequest(getCampaignFeSdk);

export const createCampaign =
  handleUnauthenticatedRequest(createCampaignsFeSdk);

export const updateCampaign = handleUnauthenticatedRequest(updateCampaignFeSdk);

export const deleteCampaign = handleUnauthenticatedRequest(deleteCampaignFeSdk);

export const deactivateCampaign = handleUnauthenticatedRequest(
  deactivateCampaignFeSdk
);

export const getCampaigns = handleUnauthenticatedRequest(getCampaignsFeSdk);

export const getOrganizationCampaigns = handleUnauthenticatedRequest(
  getOrganizationCampaignsFeSdk
);

export const getAdminOrganizationCampaigns = handleUnauthenticatedRequest(
  getAdminOrganizationCampaignsFeSdk
);

export const deleteSegmentation = handleUnauthenticatedRequest(
  deleteSegmentationFeSdk
);

export const createSegmentationJob = handleUnauthenticatedRequest(
  createSegmentationJobFeSdk
);

export const getSegmentationJobStatus = handleUnauthenticatedRequest(
  getSegmentationJobStatusFeSdk
);

export const getOverlappingCampaigns = handleUnauthenticatedRequest(
  getOverlappingCampaignsFeSdk
);

export const getCampaignStatistics = handleUnauthenticatedRequest(
  getCampaignStatisticsFeSdk
);

export const getIsSsoEnabled =
  handleUnauthenticatedRequest(getIsSsoEnabledFeSdk);
