import {
  Caption,
  Flex,
  Margin,
  css,
  useOnMount,
  useOnUnmount,
} from '@kivra/react-components';
import type {
  Campaign,
  Segmentation,
  SegmentationJobInformation,
  SegmentationJobRequest,
  SegmentationJobStatus,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import React, { useEffect, useState } from 'react';

import type { ApiResponseError } from '@kivra/sdk/common';
import { captureException } from '@kivra/sdk/log';
import { useCampaign } from '../../../../../context/campaignContext';
import { useSender } from '../../../../../context/senderContext';
import { useCurrentOrganization } from '../../../../../context/sendersContext';
import type { SegmentationError } from '../../../../../types/editorValidationErrors';
import { getCopy } from '../../../../../util/copy';
import { getCampaign } from '../../../../../util/handleUnauthorizedErrorWrapper';
import { handleSegmentationFile } from '../utils/uploadInput';
import { useSegmentationPoll } from '../utils/useSegmentationPoll';
import { Block } from '../block/Block';
import { Body } from './Body';
import { DeleteDialog } from './DeleteDialog';

interface Props {
  createCampaign: () => Promise<Campaign>;
  onChange: (ongoing: boolean) => void;
}

export interface SegmentationChangeEvent {
  value?: SegmentationJobRequest;
  error?: SegmentationError;
}

export type FileValidation = Pick<
  SegmentationJobInformation,
  'acceptedRows' | 'rejectedRows'
>;

// TODO: do we really need all these states? is preparingRequest & handleFilesError needed now that we do BE file validation?
export type SegmentationUploadState =
  | {
      status: 'fetched';
      segmentation: Segmentation;
    }
  | {
      status: 'preparingRequest';
    }
  | {
      status: 'uploading';
    }
  | {
      status: 'processing';
      jobStatus: SegmentationJobStatus;
    }
  | {
      status: 'backendError';
      error: ApiResponseError;
    }
  | {
      status: 'handleFilesError';
      error: SegmentationError;
    }
  | {
      status: 'none';
    };

export const SegmentationUploadContainer = ({
  createCampaign,
  onChange,
}: Props): React.JSX.Element => {
  const { key: senderKey } = useSender();
  const { organizationId } = useCurrentOrganization();
  const { campaign, createSegmentationJob } = useCampaign(false);
  const [fileValidation, setFileValidation] = useState<
    FileValidation | undefined
  >();
  const [state, setState] = useState<SegmentationUploadState>(
    campaign?.segmentation
      ? { status: 'fetched', segmentation: campaign.segmentation }
      : { status: 'none' }
  );

  useEffect(() => {
    if (
      ['preparingRequest', 'uploading', 'processing'].includes(state.status)
    ) {
      onChange(true);
    } else {
      onChange(false);
    }
  }, [state]);

  const [openDialog, setOpenDialog] = useState(false);
  const [startPolling, pollingState, unsubscribePolling] =
    useSegmentationPoll();

  useOnMount(() => {
    if (
      campaign?.segmentation?.status === 'ongoing' &&
      campaign.segmentation.jobKey
    ) {
      startPollingProcess(campaign.segmentation.jobKey);
    }
  });

  useOnUnmount(() => unsubscribePolling());

  useEffect(() => {
    if (campaign) {
      if (pollingState.pollStatus === 'started' && pollingState.pollInfo) {
        setState({ status: 'processing', jobStatus: pollingState.pollInfo });
      }
      if (pollingState.pollStatus === 'complete') {
        getCampaign(organizationId, senderKey, campaign.id)
          .then(campaign => {
            if (campaign.segmentation) {
              setState({
                status: 'fetched',
                segmentation: campaign.segmentation,
              });
            }
          })
          .catch(error => {
            setState({ status: 'backendError', error });
          });
      }
    }
  }, [pollingState, campaign?.id, senderKey]);

  const startPollingProcess = (jobKey: string): void => {
    startPolling(organizationId, senderKey, jobKey);
  };

  const onHandleFilesError = (error: SegmentationError): void => {
    setState({
      status: 'handleFilesError',
      error: error,
    });
  };

  const onHandleFiles = (files: FileList): Promise<SegmentationChangeEvent> => {
    setFileValidation(undefined);
    setState({
      status: 'preparingRequest',
    });
    return handleSegmentationFile(files);
  };

  const onUploadSegmentation = async ({
    error,
    value,
  }: SegmentationChangeEvent): Promise<void> => {
    if (error) {
      onHandleFilesError(error);
    }
    if (value) {
      setFileValidation(undefined);
      setState({ status: 'uploading' });
      const campaignId = campaign ? campaign.id : (await createCampaign()).id;
      try {
        const segmentationPollResponse = await createSegmentationJob(
          value,
          campaignId
        );
        const { acceptedRows, rejectedRows } = segmentationPollResponse;
        setFileValidation({ acceptedRows, rejectedRows });
        startPollingProcess(segmentationPollResponse.jobKey);
      } catch (err) {
        const error = err as ApiResponseError;
        setState({ status: 'backendError', error });
        captureException(error);
      }
    }
  };

  const showDeleteDialog = (): void => {
    setOpenDialog(true);
  };

  const onSegmentationDeleted = (): void => {
    setOpenDialog(false);
    setState({ status: 'none' });
  };

  return (
    <Block.Wrapper
      dataTestId="uploadSegmentationBlock"
      label={getCopy('campaigns__upload_segmentation')}
      tooltipText={`${getCopy('campaigns__segmentation_tooltip')}\n\n${getCopy(
        'campaigns__segmentation_tooltip_example'
      )}:\n${getCopy('campaigns__segmentation_personal_number')}`}
    >
      <Margin bottom={24} top={8}>
        <Flex alignItems="center" direction="column">
          <Caption align="center" color="$text-secondary" gutterBottom={true}>
            {getCopy('campaigns__format')}
            <span
              className={css({
                color: '$text-primary',
                fontWeight: 'bold',
              })}
            >
              {` csv ${getCopy('campaigns__file')}`}
            </span>
          </Caption>
          <Body
            state={state}
            fileValidation={fileValidation}
            onHandleFiles={onHandleFiles}
            onUploadSegmentation={onUploadSegmentation}
            onDeleteSegmentation={showDeleteDialog}
          />
          {openDialog && (
            <DeleteDialog
              onClose={() => setOpenDialog(false)}
              onSuccess={onSegmentationDeleted}
            />
          )}
        </Flex>
      </Margin>
    </Block.Wrapper>
  );
};
