import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import type { ImageChangeEvent } from '../ImageUpload';
import type { SegmentationChangeEvent } from '../segmentation/SegmentationUploadContainer';

export const minWidth = 414;
export const maxWeight = 300000;
export const maxWeightInKB = maxWeight / 1000;
// 16:9 ratio (ish)
export const minRatio = 1.75;
export const maxRatio = 2;

interface SizedEvent {
  target: {
    width: number;
    height: number;
  };
}

/**
 * The image must be between aspect ratio 16x9 and 2:1
 * Be at least 414 in width
 * Max size of 300kb
 */

const isFileSizeError = (file: File): boolean => file.size > maxWeight;

const isAspectRatioError = (event: SizedEvent): boolean => {
  const ratio = event.target.width / event.target.height;
  return minRatio > ratio || ratio > maxRatio;
};

const isMinWidthError = (event: SizedEvent): boolean => {
  return minWidth > event.target.width;
};

// This is needed to check that an event has a width and height
const isSizedEvent = (event: FixThisAnyLater): event is SizedEvent => {
  return (
    event?.target &&
    event.target.width !== undefined &&
    event.target.height !== undefined
  );
};

export const handleImageFile = async (
  files: FileList
): Promise<ImageChangeEvent> => {
  const file = files[0];
  if (isFileSizeError(file)) {
    return Promise.resolve({ error: 'size_to_big' });
  }
  try {
    const base64Image = await toBase64(file);
    const event = await getImageEvent(base64Image);
    if (!isSizedEvent(event)) {
      return Promise.resolve({ error: 'unknown' });
    } else if (isMinWidthError(event)) {
      return Promise.resolve({ error: 'min_width' });
    } else if (isAspectRatioError(event)) {
      return Promise.resolve({ error: 'aspect_ratio' });
    }
    return Promise.resolve({ value: base64Image });
  } catch (e) {
    return Promise.reject('unknown');
  }
};

const getImageEvent = (base64Image: string): Promise<Event> =>
  new Promise((resolve: (event: Event) => void, reject) => {
    const image = new Image();
    image.onload = event => resolve(event);
    image.onerror = () => reject();
    image.src = base64Image;
  });

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject();
      }
    };
    reader.onerror = () => reject();
    reader.readAsDataURL(file);
  });

export const handleSegmentationFile = async (
  files: FileList
): Promise<SegmentationChangeEvent> => {
  const file = files[0];
  return file
    .text()
    .then(fileContent => {
      return {
        value: {
          filename: file.name,
          fileContent,
        },
      };
    })
    .catch(error => ({ error }));
};
