import { useOnMount } from '@kivra/react-components';
import { captureException } from '@kivra/sdk/log';
import type { SenderType } from '@kivra/sdk/types/sender';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Content } from '../../components/campaignLayout/Content';
import { Main } from '../../components/campaignLayout/Main';
import { SideBar } from '../../components/campaignLayout/SideBar';
import { Preview } from '../../components/preview';
import { useCampaign, useCampaignType } from '../../context/campaignContext';
import { useConfig } from '../../context/globalContext';
import { useSender } from '../../context/senderContext';
import type {
  CampaignForm,
  CampaignFormErrors,
} from '../../types/campaignForm';
import type { PreviewFeedbackState } from '../../types/previewFeedbackType';
import { isReceiptSender } from '../../util/senderType';
import { EditInformation } from './components/editor/EditInformation';
import { Editor } from './components/editor/Editor';
import { EditorTopBar } from './components/editor/EditorTopBar';
import { PreviewFeedback } from './components/editor/PreviewFeedback';
import { PublishDialog } from './components/editor/PublishDialog';
import { SegmentationUploadContainer } from './components/editor/segmentation/SegmentationUploadContainer';
import {
  areErrorsAcceptableInDraft,
  createCampaignRequest,
  getDirtyFields,
} from './components/editor/utils/campaignEditor';
import { createCampaignForm } from './components/editor/utils/createCampaignForm';
import type { PublishableCampaign } from './components/editor/utils/publishableCampaign';
import { isCampaignPublishable } from './components/editor/utils/publishableCampaign';

export const CampaignEditor = (): React.JSX.Element => {
  const { kivra_campaigns_hide_segmentation: hideSegmentation } = useConfig();
  const { type: senderType } = useSender();
  const { saveCampaign, campaign } = useCampaign(false);
  const { campaignType, isTagCampaign } = useCampaignType();

  const form = useForm<CampaignForm>({
    mode: 'onTouched',
    defaultValues: createCampaignForm(campaignType, campaign),
    shouldUnregister: false,
  });

  useEffect(() => {
    form.reset();
  }, [campaignType]);

  useOnMount(form.trigger);

  const [campaignToPublish, setCampaignToPublish] =
    useState<PublishableCampaign | null>(null);
  const [previewFeedback, setPreviewFeedback] = useState<
    PreviewFeedbackState | undefined
  >();

  const save = async (formValues: CampaignForm): Promise<Campaign> => {
    const dirtyFields = getDirtyFields(form.formState, formValues);
    const campaignRequest = createCampaignRequest(dirtyFields);
    return saveCampaign({ ...campaignRequest }).then(campaign => {
      form.reset(formValues, { keepDirty: false, keepErrors: true });
      return campaign;
    });
  };

  const initPublishFlow = (): void => {
    const publishCandidate = {
      ...createCampaignRequest(form.getValues()),
    };
    if (isCampaignPublishable(publishCandidate)) {
      setCampaignToPublish(publishCandidate);
    } else {
      setPreviewFeedback({ type: 'publishError' });
    }
  };

  const saveOrPublish = async (formValues: CampaignForm): Promise<void> => {
    if (formValues.submitActionType === 'publish') {
      initPublishFlow();
    } else {
      save(formValues)
        .then(() => {
          setPreviewFeedback({ type: 'serviceSuccess' });
        })
        .catch(error => {
          captureException(error);
          setPreviewFeedback({ type: 'serviceError', error });
        });
    }
  };

  const onSubmit = form.handleSubmit(saveOrPublish, errors => {
    const formValues = form.getValues();
    if (
      areErrorsAcceptableInDraft(errors as CampaignFormErrors) &&
      formValues.submitActionType === 'save'
    ) {
      void saveOrPublish(formValues);
    } else {
      setPreviewFeedback({
        type:
          formValues.submitActionType === 'save'
            ? 'saveDraftError'
            : 'publishError',
      });
    }
  });

  const submitHandler = async (): Promise<void> => {
    setPreviewFeedback(undefined);
    await form.trigger('submitActionType');
    void onSubmit();
  };

  const previewFeedbackElement = previewFeedback ? (
    <PreviewFeedback
      state={previewFeedback}
      onClose={() => setPreviewFeedback(undefined)}
      formErrors={form.formState.errors as CampaignFormErrors}
    />
  ) : null;

  return (
    <FormProvider {...form}>
      <EditorTopBar submitHandler={submitHandler} />
      <Main>
        <SideBar data-test-id="CampaignEditor">
          <EditInformation />
          <Editor />
          {isSegmentationEnabled(
            hideSegmentation,
            senderType,
            isTagCampaign
          ) && (
            <Controller
              control={form.control}
              name="isSegmentationJobOngoing"
              rules={{
                validate: {
                  segmentationStatusIsOngoing: (value: boolean) => !value,
                },
              }}
              render={props => {
                return (
                  <SegmentationUploadContainer
                    onChange={props.field.onChange}
                    createCampaign={() =>
                      saveCampaign()
                        .then(campaign => {
                          setPreviewFeedback({ type: 'serviceSuccess' });
                          return campaign;
                        })
                        .catch(error => {
                          setPreviewFeedback({ type: 'serviceError', error });
                          captureException(error);
                          throw error;
                        })
                    }
                  />
                );
              }}
            />
          )}
        </SideBar>
        <Content>
          <Preview
            previewFeedbackElement={previewFeedbackElement}
            campaign={form.getValues()}
          />
        </Content>
      </Main>

      {campaignToPublish && (
        <PublishDialog
          save={() => save(form.getValues())}
          onClose={() => setCampaignToPublish(null)}
          campaign={campaignToPublish}
        />
      )}
    </FormProvider>
  );
};

function isSegmentationEnabled(
  hideSegmentation: boolean,
  senderType: SenderType,
  byTag: boolean
): boolean {
  return !hideSegmentation && !isReceiptSender(senderType) && !byTag;
}
