import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';

import { styled } from '@kivra/react-components';
import type { CampaignForm } from '../../types/campaignForm';
import type { PreviewType } from '../../types/previewType';
import { PreviewProvider } from '../../context/PreviewProvider';
import { SelectDevice } from './SelectDevice';
import { Zoom } from './Zoom';
import { Frame } from './Frame';
import { DesktopLayout } from './desktop/DesktopLayout';
import { PhoneLayout } from './PhoneLayout';
import { ReceiptLayout } from './ReceiptLayout';

interface Props {
  campaign: Campaign | CampaignForm;
  previewFeedbackElement?: ReactNode;
}

export const Preview = ({
  campaign,
  previewFeedbackElement,
}: Props): React.JSX.Element => {
  const [previewType, setPreviewType] = useState<PreviewType>('phone');
  const [zoomRatio, setZoomRatio] = useState(1);

  useEffect(() => {
    if (campaign.documentType === 'receipt') {
      setPreviewType('phone_receipt');
    } else if (previewType === 'phone_receipt') {
      setPreviewType('phone');
    }
  }, [campaign.documentType]);

  return (
    <PreviewProvider
      zoomRatio={zoomRatio}
      campaign={campaign}
      previewType={previewType}
    >
      <Root>
        {previewType !== 'phone_receipt' && (
          <DeviceSelectorWrapper>
            <SelectDevice
              onDeviceSelected={setPreviewType}
              previewType={previewType}
            />
          </DeviceSelectorWrapper>
        )}
        {previewFeedbackElement && (
          <ErrorMessageWrapper>{previewFeedbackElement}</ErrorMessageWrapper>
        )}
        <Frame previewType={previewType}>{layouts[previewType]}</Frame>
        <Zoom zoomRatio={zoomRatio} setZoomRatio={setZoomRatio} />
      </Root>
    </PreviewProvider>
  );
};

const layouts: Record<PreviewType, React.JSX.Element> = {
  phone_receipt: <ReceiptLayout />,
  phone: <PhoneLayout />,
  desktop: <DesktopLayout />,
};

const Root = styled('div')({
  backgroundColor: '!!$black',
  width: '100%',
  position: 'relative',
  minHeight: '100%',
  overflow: 'auto',
});

const DeviceSelectorWrapper = styled('div')({
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 100,
  width: '100%',
  paddingTop: '$spacing-16',
  paddingRight: '$spacing-16',
  textAlign: 'right',
  height: 0, // ensure device selector doesn't occupy space (its content should still be visible through implicit overflow style (`visible`))
});

const ErrorMessageWrapper = styled('div')({
  position: 'fixed',
  top: 'calc($responsive-topbar-height + $spacing-16)',
  left: '$spacing-16',
  right: '$spacing-16',
  zIndex: 200,
});
