import { useRef, useState } from 'react';
import type { ApiResponseError } from '@kivra/sdk/common';
import { sleep } from '@kivra/sdk/common';
import type { SegmentationJobStatus } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { getSegmentationJobStatus } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { useOnMount } from '@kivra/react-components';

type PollStatus = 'not-started' | 'started' | 'error' | 'complete';

interface State {
  pollInfo?: SegmentationJobStatus;
  pollStatus: PollStatus;
  error?: ApiResponseError;
}

export const useSegmentationPoll = (): readonly [
  (organizationId: string, senderKey: string, jobKey: string) => void,
  State,
  () => void,
] => {
  const [state, setState] = useState<State>({
    pollStatus: 'not-started',
    pollInfo: undefined,
    error: undefined,
  });
  useOnMount(() => {
    mountedRef.current = true;
  });
  const mountedRef = useRef<boolean>(true);
  const setStateRef = useRef<(state: State) => void>(setState);

  const pollSegmentation = async (
    organizationId: string,
    senderKey: string,
    jobKey: string
  ): Promise<void> => {
    getSegmentationJobStatus(organizationId, senderKey, jobKey)
      .then(async pollInfo => {
        switch (pollInfo.status) {
          case 'completed':
            setStateRef.current({
              pollInfo,
              pollStatus: 'complete',
              error: undefined,
            });
            break;
          case 'canceled':
          case 'error':
            setStateRef.current({
              pollInfo,
              pollStatus: 'error',
              error: undefined,
            });
            break;
          case 'ongoing':
            setStateRef.current({
              pollInfo,
              pollStatus: 'started',
              error: undefined,
            });
            await sleep(2000);
            if (mountedRef.current) {
              await pollSegmentation(organizationId, senderKey, jobKey);
            }
            break;
        }
      })
      .catch(error => {
        setStateRef.current({
          pollInfo: undefined,
          pollStatus: 'error',
          error,
        });
      });
  };

  const startPolling = (
    organizationId: string,
    senderKey: string,
    jobKey: string
  ): void => {
    void pollSegmentation(organizationId, senderKey, jobKey);
  };
  const unsubscribe = (): void => {
    mountedRef.current = false;
  };

  return [startPolling, state, unsubscribe] as const;
};
