import React, { useEffect } from 'react';
import type { ClientStatusType } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { CampaignsContextProvider } from '../../../context/campaignsContext';
import { useCurrentOrganization } from '../../../context/sendersContext';
import { campaignServiceStates } from '../../../util/useCampaignsService';
import { CampaignsBlock } from '../../../components/CampaignsBlock';
import { NoCampaigns } from '../../../components/NoCampaigns';
import { useSender } from '../../../context/senderContext';
import { CampaignTableContainer } from './campaignTable/CampaignTable';

interface Props {
  types: ClientStatusType[];
}

export const Campaigns = ({ types }: Props): React.JSX.Element => {
  const { key: senderKey } = useSender();
  const { organizationId } = useCurrentOrganization();
  const serviceStates = campaignServiceStates({
    organizationId,
    senderKey,
    types,
  });

  const fetchCampaigns = (): void => {
    serviceStates.forEach(state => {
      state.callService();
    });
  };

  useEffect(() => {
    fetchCampaigns();
  }, [senderKey]);

  return (
    <CampaignsContextProvider value={{ reloadCampaigns: fetchCampaigns }}>
      {serviceStates.map(({ serviceState, type }) => (
        <CampaignsBlock key={type} type={type} serviceState={serviceState}>
          {serviceState.response?.length ? (
            <CampaignTableContainer
              campaigns={serviceState.response}
              campaignType={type}
            />
          ) : (
            <NoCampaigns type={type} />
          )}
        </CampaignsBlock>
      ))}
    </CampaignsContextProvider>
  );
};
