import { getHeimdallQueryParamerters } from '@kivra/sdk/authentication';
import { getQueryValue } from '@kivra/sdk/common';
import type { QueryParams } from '../types/queryParams';

export function getQueryParams(href: string): QueryParams {
  return {
    locale: getAndDecodeOption('locale', href),
    prefersColorScheme: getPrefersColorScheme(
      getAndDecodeOption('prefers_color_scheme', href)
    ),
    heimdall: getHeimdallQueryParamerters(href),
  };
}

function getPrefersColorScheme(
  scheme: string | null
): 'light' | 'dark' | undefined {
  switch (scheme) {
    case 'light':
    case 'dark':
      return scheme;
    default:
      return undefined;
  }
}

function getAndDecodeOption(name: string, href: string): string | null {
  const value = getQueryValue(name, href);
  return value && decodeURIComponent(value);
}
