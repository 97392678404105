import React from 'react';
import type { BannerConfig } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { styled } from '@kivra/react-components';
import { getCopy } from '../../util/copy';
import { BannerBackground } from './controls/BannerBackgound';
import { BannerText } from './controls/BannerText';

type Props = {
  bannerOptions: BannerConfig;
  setBannerOptions: (options: Partial<BannerConfig>) => void;
};

export const BannerControls = ({
  bannerOptions,
  setBannerOptions,
}: Props): React.JSX.Element => (
  <Wrapper>
    <BannerBackground
      color={bannerOptions.backgroundColor.value}
      onChange={(color: string) =>
        setBannerOptions({
          backgroundColor: { value: color },
        })
      }
    />
    <BannerText
      rows={2}
      title={getCopy('campaigns__banner_editor_title')}
      config={bannerOptions.title}
      onChange={title => setBannerOptions({ title })}
    />
    <BannerText
      rows={3}
      title={getCopy('campaigns__banner_editor_body')}
      config={bannerOptions.body}
      onChange={body => setBannerOptions({ body })}
    />
    <BannerText
      rows={1}
      title={getCopy('campaigns__banner_editor_button')}
      config={bannerOptions.button}
      onChange={button => setBannerOptions({ button })}
    />
  </Wrapper>
);

const Wrapper = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});
