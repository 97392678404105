import {
  Caption,
  ErrorCard,
  Heading,
  LazyComponent,
  Margin,
  styled,
} from '@kivra/react-components';
import { captureException } from '@kivra/sdk/log';
import type {
  Campaign,
  ClientStatusType,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import React from 'react';
import { getCopy } from '../../util/copy';
import { statisticStatusCopy } from '../../util/copyKeys';
import { getDayMonthTimeDate, getTimePeriodText } from '../../util/dates';
import { FetchCampaignMetrics } from '../FetchCampaignMetrics';

interface Props {
  campaign: Campaign;
}

export const Statistics = ({ campaign }: Props): React.JSX.Element => {
  return (
    <>
      <Heading size="small" gutterBottom>
        {getStatisticsText(campaign.clientStatus)}
      </Heading>
      <FetchCampaignMetrics campaign={campaign}>
        {({ lastUpdated, metrics }) => {
          return (
            <>
              <ItalicCaption gutterBottom={true} color="$text-secondary">
                {lastUpdated && getUpdatedAtText(lastUpdated, campaign)}
              </ItalicCaption>
              <LazyComponent
                Component={() =>
                  import('./Illustrations').then(
                    ({ Illustrations }) => Illustrations
                  )
                }
                onError={error => {
                  captureException(error);
                  throw error;
                }}
                ErrorComponent={() => (
                  <Margin bottom={12}>
                    <ErrorCard severity="high">
                      <ErrorCard.Title>
                        {getCopy('campaigns__statistics_error')}
                      </ErrorCard.Title>
                    </ErrorCard>
                  </Margin>
                )}
                render={Illustrations => (
                  <Illustrations
                    activePeriod={getTimePeriodText(
                      campaign.activeFrom,
                      campaign.attachTo
                    )}
                    hideClickStats={
                      !campaign.destinationUrl && !campaign.useSso
                    }
                    documentType={campaign.documentType}
                    statistics={metrics}
                  />
                )}
              />
            </>
          );
        }}
      </FetchCampaignMetrics>
    </>
  );
};

const getUpdatedAtText = (
  lastUpdatedCampaign: string,
  campaign: Campaign
): string => {
  if (campaign.clientStatus === 'previous') {
    if (!campaign.activeFrom || !campaign.activeTo) {
      return '';
    }
    return `${getDayMonthTimeDate(campaign.activeFrom)} - ${getDayMonthTimeDate(
      campaign.activeTo
    )}`;
  }
  return `${getCopy(
    'campaigns__statistics_updated_last'
  )}: ${getDayMonthTimeDate(lastUpdatedCampaign)}.`;
};

const getStatisticsText = (campaignStatus: ClientStatusType): string => {
  if (campaignStatus === 'draft' || campaignStatus === 'upcoming') {
    return '';
  }
  return getCopy('campaigns__statistics_for', {
    status: getCopy(statisticStatusCopy[campaignStatus]).toLocaleLowerCase(),
  });
};

const ItalicCaption = styled(Caption)({
  fontStyle: 'italic',
});
