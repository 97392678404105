import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { createContext, useContext } from 'react';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import type { CampaignForm } from '../types/campaignForm';
import type { PreviewType } from '../types/previewType';

export interface PreviewContext {
  campaign: Campaign | CampaignForm;
  getZoomRatioPixelsString: (pixels: number) => string;
  getZoomRatioPixels: (pixels: number) => number;
  previewType: PreviewType;
}

export const PreviewContext = createContext<PreviewContext>(
  null as FixThisAnyLater
);

const { Consumer: PreviewContextConsumer, Provider: PreviewContextProvider } =
  PreviewContext;

const hookCreator =
  <T>(map: (c: PreviewContext) => T) =>
  (): T => {
    const campaigns = useContext(PreviewContext);
    return map(campaigns);
  };

export const useCalcPixelsString = hookCreator(c => c.getZoomRatioPixelsString);
export const useCalcPixels = hookCreator(c => c.getZoomRatioPixels);
export const usePreviewCampaign = hookCreator(c => c.campaign);
export const usePreviewType = hookCreator(c => c.previewType);
export { PreviewContextProvider, PreviewContextConsumer };
