import {
  ErrorPage,
  FullPageLoader,
  KivraTheme,
  useOnMount,
} from '@kivra/react-components';
import { captureException, initLogger } from '@kivra/sdk/log';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import React, { useCallback, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { getConfig, getCopy, initFeSdk } from './bootstrap';
import type { GlobalContext } from './context/globalContext';
import { GlobalContextProvider } from './context/globalContext';
import { Routes } from './routes/Routes';
import type { Config } from './types/config';
import type { QueryParams } from './types/queryParams';
import { setCopy } from './util/copy';

interface AppProps {
  queryParams: QueryParams;
}

type StartupErrorType = 'unknown' | 'cookieError';

export const App: React.FC<AppProps> = ({ queryParams }): React.JSX.Element => {
  const [config, setConfig] = useState<Config>();
  const [error, setError] = useState<StartupErrorType>();
  const initApp = async (): Promise<void> => {
    const config = await getConfig();
    initLogger({
      dsn: config.sentry_dsn_kivra_campaign,
      enabled: window.location.port === '',
    });
    initFeSdk(config);
    const copy = await getCopy();
    setCopy(copy);
    setConfig(config);
  };

  const onInitError = useCallback(
    (error: FixThisAnyLater) => {
      if (config) {
        captureException(error);
      }
      setError('unknown');
    },
    [config, setError]
  );

  useOnMount(() => {
    initApp().catch(onInitError);
  });

  if (error) {
    return <ErrorPage useDefaultTexts />;
  }
  if (!config) {
    return <FullPageLoader />;
  }

  const globalContext: GlobalContext = {
    config,
    global: window,
    heimdallParameters: queryParams.heimdall,
  };

  return (
    <BrowserRouter>
      <GlobalContextProvider value={globalContext}>
        <KivraTheme colorScheme="light">
          <Routes />
        </KivraTheme>
      </GlobalContextProvider>
    </BrowserRouter>
  );
};
