import { Flex } from '@kivra/react-components';
import {
  TextBoldIcon,
  TextItalicIcon,
  TextUnderlineIcon,
} from '@kivra/react-components/icons';
import type { TextOptions } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import React from 'react';
import { Selectable } from './Selectable';

type Props = Pick<TextOptions, 'bold' | 'italic' | 'underline'> & {
  onChange: (
    styles: Pick<TextOptions, 'bold' | 'italic' | 'underline'>
  ) => void;
};

const styles: (keyof Omit<Props, 'onChange'>)[] = [
  'bold',
  'italic',
  'underline',
];

const icons: Record<keyof Omit<Props, 'onChange'>, React.JSX.Element> = {
  bold: <TextBoldIcon size={16} colorToken="currentColor" />,
  italic: <TextItalicIcon size={16} colorToken="currentColor" />,
  underline: <TextUnderlineIcon size={16} colorToken="currentColor" />,
};

export const StyleSelector = (props: Props): React.JSX.Element => {
  return (
    <Flex>
      {styles.map(style => (
        <Selectable
          key={style}
          selected={props[style]}
          onClick={() => props.onChange({ ...props, [style]: !props[style] })}
        >
          {icons[style]}
        </Selectable>
      ))}
    </Flex>
  );
};
