import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import type { CampaignForm } from '../../../../../types/campaignForm';
import type { CampaignType } from '../../../../../util/campaignType';

const defaultReceiptCampaignFormValues: Omit<CampaignForm, 'submitActionType'> =
  {
    bannerConfig: null,
    dates: {
      attachFrom: null,
      attachTo: null,
      activeFrom: null,
      activeTo: null,
    },
    destinationUrl: '',
    documentType: 'receipt',
    image: '',
    position: 'bottom',
    targetGroup: 'user',
    tag: '',
    title: '',
    useSso: false,
    isSegmentationJobOngoing: false,
  };

const defaultTenantCampaignFormValues: Omit<CampaignForm, 'submitActionType'> =
  {
    bannerConfig: null,
    dates: {
      attachFrom: null,
      attachTo: null,
      activeFrom: null,
      activeTo: null,
    },
    destinationUrl: '',
    documentType: 'all',
    image: '',
    position: 'top',
    targetGroup: 'all',
    tag: '',
    title: '',
    useSso: false,
    isSegmentationJobOngoing: false,
  };

const getDate = (dateString: string | null): Date | null =>
  dateString ? new Date(dateString) : null;

export const createCampaignForm = (
  campaignType: CampaignType,
  campaign?: Campaign
): CampaignForm => {
  const formCampaignValues = campaign
    ? {
        bannerConfig: campaign.bannerConfig,
        dates: {
          activeFrom: getDate(campaign.activeFrom),
          activeTo: getDate(campaign.activeTo),
          attachFrom: getDate(campaign.attachFrom),
          attachTo: getDate(campaign.attachTo),
        },
        clientStatus: campaign.clientStatus,
        destinationUrl: campaign.destinationUrl ?? '',
        documentType: campaign.documentType,
        image: campaign.image || '',
        isSegmentationJobOngoing: campaign.segmentation?.status === 'ongoing',
        position: campaign.position,
        tag: campaign.tag || '',
        targetGroup: campaign.targetGroup,
        title: campaign.title,
        useSso: campaign.useSso,
      }
    : createLocalCampaignDraft(campaignType);
  return {
    ...formCampaignValues,
    submitActionType: 'save',
  };
};

const createLocalCampaignDraft = (
  campaignType: CampaignType
): Omit<CampaignForm, 'submitActionType'> => {
  switch (campaignType) {
    case 'by-tag':
    case 'postal':
      return defaultTenantCampaignFormValues;
    case 'receipt':
      return defaultReceiptCampaignFormValues;
  }
};
