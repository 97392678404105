import { Button, ErrorPage, Link, Margin } from '@kivra/react-components';
import type { HeimdallErrorCode } from '@kivra/sdk/authentication';
import React from 'react';
import { useConfig } from '../../context/globalContext';
import { logoutUser } from '../../util/authentication';
import { getCopy } from '../../util/copy';

type Props = {
  error: LoginError;
};

export type LoginError = HeimdallErrorCode | 'unknown';

export const HeimdallError = ({ error }: Props): React.JSX.Element => {
  const { kivra_campaigns_uri_origin } = useConfig();
  const errorCopy =
    error === 'invalid_session'
      ? {
          title: getCopy('campaigns__login_error_invalid_session_title'),
          body: (
            <span>
              {getCopy('campaigns__login_error_invalid_session_body')}
              <Link to={kivra_campaigns_uri_origin}>
                {kivra_campaigns_uri_origin}
              </Link>
            </span>
          ),
          button: getCopy('campaigns__login_error_invalid_session_button'),
        }
      : {
          title: getCopy('error_generic__title'),
          body: getCopy('campaigns__dialog_error_generic_body'),
          button: getCopy('btn__try_again'),
        };
  return (
    <ErrorPage title={errorCopy.title}>
      {errorCopy.body}
      <Margin top={16}>
        <Button
          size="medium"
          onClick={() => logoutUser({ shouldClearTenantStorage: false })}
        >
          {errorCopy.button}
        </Button>
      </Margin>
    </ErrorPage>
  );
};
