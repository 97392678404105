import type { BannerConfig } from '@sender-portal-fe/util-shared/src/sdk/campaigns';
import { getCopy } from '../../util/copy';

const black = '#212121';
const white = '#ffffff';

export const getDefaultBannerOptions = (): BannerConfig => ({
  version: 1,
  title: {
    value: getCopy('campaigns__banner_editor_title_default_value'),
    options: {
      alignment: 'left',
      font: 'Open Sans',
      size: 44,
      bold: true,
      italic: false,
      underline: false,
      color: black,
      visible: true,
      maxChars: 40,
    },
  },
  body: {
    value: getCopy('campaigns__banner_editor_body_default_value'),
    options: {
      alignment: 'left',
      font: 'Open Sans',
      size: 20,
      bold: false,
      italic: false,
      underline: false,
      color: black,
      visible: true,
      maxChars: 150,
    },
  },
  button: {
    value: getCopy('campaigns__banner_editor_button_default_value'),
    options: {
      backgroundColor: black,
      alignment: 'center',
      font: 'Open Sans',
      size: 28,
      bold: true,
      italic: false,
      underline: false,
      color: white,
      visible: true,
      maxChars: 15,
    },
  },
  backgroundColor: {
    value: '#eeeeee',
  },
});
