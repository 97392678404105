import type { ApiResponseError } from '@kivra/sdk/common';
import { useEffect } from 'react';
import { useStateIfMounted } from '@kivra/react-components';

export const useServiceCaller = <T>(
  serviceCall: () => Promise<T>,
  startOnMount = true
): readonly [state: State<T>, () => void] => {
  const [state, setState] = useStateIfMounted<State<T>>({
    status: startOnMount ? 'start' : 'initial',
    error: undefined,
    response: undefined,
  });

  useEffect(() => {
    if (state.status === 'start') {
      setState({ status: 'loading', response: undefined, error: undefined });
      serviceCall()
        .then(result => {
          setState({ status: 'success', response: result, error: undefined });
        })
        .catch(error => {
          setState({ status: 'error', error, response: undefined });
        });
    }
  }, [state, setState, serviceCall]);

  const callService = (): void => {
    setState({ status: 'start', error: undefined, response: undefined });
  };

  return [state, callService] as const;
};

export type State<T> =
  | {
      status: 'start';
      error: undefined;
      response: undefined;
    }
  | {
      status: 'initial';
      error: undefined;
      response: undefined;
    }
  | {
      status: 'loading';
      error: undefined;
      response: undefined;
    }
  | {
      status: 'success';
      error: undefined;
      response: T;
    }
  | {
      status: 'error';
      error: ApiResponseError;
      response: undefined;
    };
